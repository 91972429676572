import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { apiUrl,} from'../Config/Config';
import axios from 'axios';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { useParams, useNavigate } from 'react-router-dom';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class List extends Component {
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };
    axios.post(apiUrl+"font/list",qs.stringify(postobject)).then(res => {         
      this.setState({ totalRecord : res.data.records_count, fontlist: res.data});
    });
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Fonts');
      if(localStorage.getItem('admin_id') === null){
      this.props.navigate('/');
      }
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
        axios.post(apiUrl+"font/list",qs.stringify(postobject)).then(res => {         
          this.setState({ totalRecord : res.data.records_count, fontlist: res.data});
        });
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          axios.post(apiUrl+"font/list",qs.stringify(postObject)).then(res => {         
            this.setState({ totalRecord : res.data.records_count, fontlist: res.data});
          });
    }


deletevehicle(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          font_id  :id,
          admin_id  : admin_id
        };
    
    axios.post(apiUrl+"font/delete",qs.stringify(postObject)).then(res => {
      if(res.data.status == "success"){
          $('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
           
              this.setState({ fontlist: res.data});
        
            setTimeout(
            function() {
              $('.success_message').html('');
            }
            .bind(this),
            3000
            );
        }
      });
}
  fontlist() {
    var fontlist = this.state.fontlist;
    if (fontlist != "undefined" && fontlist != null) {
      if (fontlist.status == "success") {
        if (Object.keys(fontlist).length > 0) {
          const fontlistDetails = fontlist.fontlist.map(
            (fontlist, Index) => {
              let sno = Index+1;
              if(fontlist.tf_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={fontlist.tf_id}>
                <td>{sno}</td>
                  <td>
                  {fontlist.tf_name}
                  </td>
                  <td>{status}</td>
                  <td>
                  {/* <Link to={{ pathname: '/font/edit/'+fontlist.tf_id}} className="" title="edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>*/}
                     <Link onClick={this.deletevehicle.bind(this,fontlist.tf_id)} className="" title="delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return fontlistDetails ;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="fonts" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">
                <div className="title">
                  <h3>Fonts</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
              <div className="add_button">  
                    <a className="add" href='/font-add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Font Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.fontlist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default withRouter(List);