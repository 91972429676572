/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import axios from 'axios';
// import { GET_SETTINGDATA, GET_UPDATESETTING, GET_FONTNAME } from '../../actions';
import Select from 'react-select';

import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import Addinputsimple from './Addinputsimple';
import { apiUrl,carImageUrl } from '../Config/Config';


class Setting extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			settings_id:'',
			settings_site_title:'',
			settings_from_name:'',
			settings_admin_email:'',
			settings_from_email:'',
			settings_mailpath:'',
			settings_company_address:'',
			settings_commission_percentage:'',
			settings_smtp_host:'',
			settings_smtp_user:'',
			settings_smtp_pass:'',
			settings_smtp_port:'',
			settings_username:'',
			settings_mobileno:'',
			settings_email_footer:'',
			settings_commision_amount:'',
			smtp_checked:false,
			Loading: false,
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			},
			hexColor:'#f17013',
			settings_public_key:'',
			settings_product_key:'',
			settings_secret_key:'',
			stripe_live_checked:false,
			stripe_sandbox_checked:false,
			notification_popup_theme: "",
			dashboard_view:'list',
			stripe_payment_type: "Normal",
			settings_domain_one:'',
			settings_domain_two:'',
			settings_embedcode:'',
			setting_goal_amount:'',
			settings_access_code:'',
			st_position_left:'',
			st_position_right:'',
			st_position_top:'',
			st_position_bottom:'',
			domainSets:'',
			user_image: [],
			user_image_name: "",
			user_image_preview: "",
			fonttype : [],
			selectedFont:'',
			fontvalue:'',
			st_position_checked:true,
			st_position:'',
			payment_page_content:'',
			supporter_additional_fee:'',
			supporter_payment: '',
			invite_description:'',
			invite_image: [],
			invite_image_name: "",
			invite_image_preview: "",
			formValues: [{ supporter_price_lab: "", supporter_price_val : "" }],
			plugin_size:'600',
		};
		var qs = require('qs');
		
		var postobject = {
			admin_id:  localStorage.getItem("admin_id")
		};

		// this.props.getSettingData(qs.stringify(postobject));




		// this.props.getFontName();


		axios.post(apiUrl+"adminpanel/adminsettings",qs.stringify(postobject)).then(res => {
			if(res.data.results){
				const formpayload = res.data.results;
				this.setState({formValues:formpayload.supporter_payment});
    			this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
				this.setState({settings_product_key:formpayload.settings_product_key});
				this.setState({setting_goal_amount:formpayload.setting_goal_amount});
				this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});				
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({domainSets : formpayload.domainSets});
				this.setState({user_image_preview: formpayload.st_car_icon});
				this.setState({settings_commision_amount: formpayload.settings_commision_amount});
				this.setState({payment_page_content: formpayload.payment_page_content});
				this.setState({notification_popup_theme:formpayload.notification_popup_theme});
				this.setState({dashboard_view:formpayload.dashboard_view});
				this.setState({stripe_payment_type:formpayload.stripe_payment_type,invite_description: formpayload.invite_description});
				this.setState({plugin_size: formpayload.plugin_size});

    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}
    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
    			}else{
    				this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
    			}

    			if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			}else{
    				this.setState({st_position_checked: false});
    			}

			    //  if(props.settings[0].font!== '' && props.settings[0].font!== null){
			    //     this.setselectedvalue(Props.settings[0].font);
			    //  }else{
			    //  	this.setState({selectedFont: [{value:'', label: 'Default'}]})
			    //  }
				 if(formpayload.invite_image){
					this.setState({invite_image_preview: 'https://admin-va.uxt.design/studentcharity/media/'+formpayload.invite_image})
				 }
			}
		});

		
		axios.get(apiUrl+'font/getfont?admin_id='+localStorage.getItem("admin_id"))
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						fonttype: res.data.fontlist
					})
				
				}else{
				//console.log("test")
				}
			});


		this.myCallback();
		this.handleInputChange  = this.handleInputChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		
    }

    handleInputChange(event) {
		console.log(event.target.value,"event.target");
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'settings_mail_from_smtp'){
      	 this.setState({smtp_checked: !this.state.smtp_checked});
      }	
      if(name === 'settings_enable_live'){
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      	  this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      }	
      if(name === 'settings_enable_sandbox'){
      	 this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      }	
      if(name === 'st_position'){
      	this.setState({st_position_checked: !this.state.st_position_checked});
      }
    }
	
    componentDidMount() {
      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
     }
	 
   onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		invite_image_name: file.name,
		invite_image_preview: reader.result,
		invite_image: file
	  });
	};
	reader.readAsDataURL(file);
  }

    handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				  
				const formPayload = this.state;

				var qs = require('qs');
				if(this.state.smtp_checked === true){
					var settings_mail_from_smtp = '1';
				}else{
					 settings_mail_from_smtp = '0';
				}
				if(this.state.stripe_live_checked === true){
					var settings_enable_live = '1';
				}else{
					 settings_enable_live = '0';
				}

				if(this.state.st_position_checked === true){
					var plugin_position = 'fixed';
				}else{
					 plugin_position = 'absolute';
				}
                
				var fonttype;
				if(formPayload.selectedFont[0]==undefined){
					fonttype = formPayload.selectedFont.value;
				}else{
					fonttype = formPayload.selectedFont[0].value;
				}
				var postObject = {
					settings_id: formPayload.settings_id,
					settings_site_title:formPayload.settings_site_title,
					settings_from_name:formPayload.settings_from_name,
					settings_admin_email:formPayload.settings_admin_email,
					settings_from_email:formPayload.settings_from_email,
					settings_mailpath:formPayload.settings_mailpath,
					settings_company_address:formPayload.settings_company_address,
					settings_commission_percentage:formPayload.settings_commission_percentage,
					site_background: formPayload.hexColor,
					smtp_host:formPayload.settings_smtp_host,
					smtp_user:formPayload.settings_smtp_user,
					smtp_pass:formPayload.settings_smtp_pass,
					smtp_port:formPayload.settings_smtp_port,
					admin_user:formPayload.settings_username,
					admin_no:formPayload.settings_mobileno,
					settings_email_footer:formPayload.settings_email_footer,
					settings_mail_from_smtp:settings_mail_from_smtp,
					settings_public_key:formPayload.settings_public_key,
					settings_product_key:formPayload.settings_product_key,
					setting_goal_amount:formPayload.setting_goal_amount,
					supporter_additional_fee:formPayload.supporter_additional_fee,
					settings_secret_key:formPayload.settings_secret_key,
					settings_enable_live:settings_enable_live,
					st_position_left:formPayload.st_position_left,
					st_position_right:formPayload.st_position_right,
					st_position_top:formPayload.st_position_top,
					st_position_bottom:formPayload.st_position_bottom,
					font 		  : fonttype,
					st_car_icon: formPayload.user_image,
					settings_commision_amount: formPayload.settings_commision_amount,
					plugin_position: plugin_position,
					payment_page_content: formPayload.payment_page_content,
					notification_popup_theme: formPayload.notification_popup_theme,
					dashboard_view:formPayload.dashboard_view,
					stripe_payment_type: formPayload.stripe_payment_type,
					invite_description : formPayload.invite_description,
					invite_image: formPayload.invite_image,
					plugin_size: formPayload.plugin_size,
				};
				
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				var domains = formPayload.domainSets
				for(let i in domains){
					formData.append('domainName[]',domains[i]['domain_name'])
				}

				var formValues = formPayload.formValues
				for(let i in formValues){
					formData.append('supporter_label[]',formValues[i]['supporter_price_lab'])
					formData.append('supporter_value[]',formValues[i]['supporter_price_val'])
				}

				axios.post(apiUrl+"adminpanel/settingsave",formData,config).then(res => {
					if(res.data.results){
						this.setState({Loading:false});
						const formpayload = res.data.results;
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');

						this.setState({formValues:formpayload.supporter_payment});
						this.setState({settings_id:formpayload.settings_id});
						this.setState({settings_site_title:formpayload.settings_site_title});
						this.setState({settings_from_name:formpayload.settings_from_name});
						this.setState({settings_admin_email:formpayload.settings_admin_email});
						this.setState({settings_from_email:formpayload.settings_from_email});
						this.setState({settings_mailpath:formpayload.settings_mailpath});
						this.setState({settings_company_address:formpayload.settings_company_address});
						this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
						this.setState({settings_smtp_host:formpayload.settings_smtp_host});
						this.setState({settings_smtp_user:formpayload.settings_smtp_user});
						this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
						this.setState({settings_smtp_port:formpayload.settings_smtp_port});
						this.setState({settings_username:formpayload.settings_username});
						this.setState({settings_mobileno:formpayload.settings_mobileno});
						this.setState({settings_email_footer:formpayload.settings_email_footer});
						this.setState({hexColor:formpayload.settings_site_background_color});
						this.setState({settings_public_key:formpayload.settings_public_key});
						this.setState({settings_product_key:formpayload.settings_product_key});
						this.setState({setting_goal_amount:formpayload.setting_goal_amount});
						this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
						this.setState({settings_secret_key:formpayload.settings_secret_key});
						this.setState({settings_embedcode:formpayload.settings_embedcode});
						this.setState({settings_access_code:formpayload.access_code});				
						this.setState({st_position_left:formpayload.st_position_left});
						this.setState({st_position_right:formpayload.st_position_right});
						this.setState({st_position_top:formpayload.st_position_top});
						this.setState({st_position_bottom:formpayload.st_position_bottom});
						this.setState({domainSets : formpayload.domainSets});
						this.setState({user_image_preview: formpayload.st_car_icon});
						this.setState({settings_commision_amount: formpayload.settings_commision_amount});
						this.setState({payment_page_content: formpayload.payment_page_content});
						this.setState({notification_popup_theme:formpayload.notification_popup_theme});
						this.setState({dashboard_view:formpayload.dashboard_view});
						this.setState({stripe_payment_type:formpayload.stripe_payment_type,invite_description: formpayload.invite_description});

						if(formpayload.settings_mail_from_smtp === '1'){
							this.setState({smtp_checked: true});
						}else{
							this.setState({smtp_checked: false});
						}
						if(formpayload.enable_live === '1'){
							this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
						}else{
							this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
						}

						if(formpayload.st_plugin_position === 'fixed'){
							this.setState({st_position_checked: true });
						}else{
							this.setState({st_position_checked: false});
						}

						//  if(props.settings[0].font!== '' && props.settings[0].font!== null){
						//     this.setselectedvalue(Props.settings[0].font);
						//  }else{
						//  	this.setState({selectedFont: [{value:'', label: 'Default'}]})
						//  }
						if(formpayload.invite_image){
							this.setState({invite_image_preview: 'https://admin-va.uxt.design/studentcharity/media/'+formpayload.invite_image})
						}
						scrollToTop();
						setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
				    }
						 
				});

				// this.props.getUpdateSetting(formData,config);
				////this.props.getUpdateSetting(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {settings_commision_amount,settings_id,settings_site_title,settings_from_name,settings_admin_email,settings_from_email,settings_company_address,settings_commission_percentage,settings_smtp_host,settings_smtp_user,settings_smtp_pass,settings_smtp_port,settings_username,settings_mobileno,settings_email_footer,settings_public_key,settings_product_key,setting_goal_amount,supporter_additional_fee,settings_secret_key,settings_domain_one,settings_domain_two,settings_embedcode,user_image_preview,selectedFont} = this.state;
		let errors = 0;

		const data = [...this.state.formValues]
		let valid = true
		for (let index = 0; index < data.length; index++) {
		  // const element = data[index];		
	
		  if(data[index].supporter_price_lab == "") {
			data[index].supporter_price_labCheck = "Label is required";
			errors++; 
			
		  }else{
			data[index].supporter_price_labCheck = "";
			
	
		  }

		  if(data[index].supporter_price_val == "") {
			data[index].supporter_price_valCheck = "Amount is required";
			errors++; 
			
		  }else{
			data[index].supporter_price_valCheck = "";	
		  }
		  
		}

      	//let formIsValid = true;
		if (!settings_site_title) { 
			errors++;
			$('.errorsettings_site_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_site_title){		
			$('.errorsettings_site_title').html('');
		}
		
		if (!settings_from_name) { 
			errors++;
			$('.errorsettings_from_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_name){
			$('.errorsettings_from_name').html('');
		}

		if (!settings_admin_email) {
			errors++; 
			$('.errorsettings_admin_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_admin_email){
			$('.errorsettings_admin_email').html('');
		}
		if (!settings_from_email) {
			errors++; 
			$('.errorsettings_from_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_email){
			$('.errorsettings_from_email').html('');
		}
		if (!settings_username) { 
			errors++;
			$('.errorsettings_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_username){
			$('.errorsettings_username').html('');
		}

		if (!settings_mobileno) { 
			errors++;
			$('.errorsettings_mobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_mobileno){
			$('.errorsettings_mobileno').html('');
		} 


		if (!settings_commision_amount) { 
			errors++;
			$('.errorsettings_commision_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_commision_amount){
			$('.errorsettings_commision_amount').html('');
		}

		if (!settings_email_footer) { 
			errors++;
			$('.errorsettings_email_footer').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_company_address){
			$('.errorsettings_email_footer').html('');
		}

		/*if (!settings_company_address) {
			errors++; console.log('settings_company_address');
			$('.errorsettings_company_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_company_address){
			$('.errorsettings_company_address').html('');
		}*/
		// if (!settings_commission_percentage) { console.log('settings_commission_percentage');
		// 	errors++;
		// 	$('.errorsettings_commission_percentage').html('<span class="errorspan">Please fill the field</span>');
		// }else if(settings_commission_percentage){
		// 	$('.errorsettings_commission_percentage').html('');
		// }

		/*if (!settings_smtp_user) {
			errors++;
			$('.errorsettings_smtp_user').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_user){
			$('.errorsettings_smtp_user').html('');
		}

		if (!settings_smtp_pass) {
			errors++;
			$('.errorsettings_smtp_pass').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_pass){
			$('.errorsettings_smtp_pass').html('');
		}

		if (!settings_smtp_host) {
			errors++;
			$('.errorsettings_smtp_host').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_host){
			$('.errorsettings_smtp_host').html('');
		}

		if (!settings_smtp_port) {
			errors++;
			$('.errorsettings_smtp_port').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_port){
			$('.errorsettings_smtp_port').html('');
		}*/
		if (!settings_public_key) {
			errors++;
			$('.errorsettings_public_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_public_key){
			$('.errorsettings_public_key').html('');
		}

		if (!settings_product_key) {
			errors++;
			$('.errorsettings_product_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_product_key){
			$('.errorsettings_product_key').html('');
		}
		if (!supporter_additional_fee) {
			errors++;
			$('.errorsupporter_additional_fee').html('<span class="errorspan">Please fill the field</span>');
		}else if(supporter_additional_fee){
			$('.errorsupporter_additional_fee').html('');
		}
		// if (!setting_goal_amount) {
		// 	errors++;
		// 	$('.errorsetting_goal_amount').html('<span class="errorspan">Please fill the field</span>');
		// }else if(setting_goal_amount){
		// 	$('.errorsetting_goal_amount').html('');
		// }
		if (!settings_secret_key) {
			errors++;
			$('.errorsettings_secret_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_secret_key){
			$('.errorsettings_secret_key').html('');
		}
	  /*if (!settings_domain_one) {
			errors++;
			$('.errorsettings_domain_title1').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_domain_one){	
			var pattern = new RegExp("^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$");

			if(pattern.test(settings_domain_one)){
				$('.errorsettings_domain_title1').html('');				
			}else{
				errors++;
				$('.errorsettings_domain_title1').html('<span class="errorspan">Incorrect domain name</span>');
			}			
			
		}else{
			$('.errorsettings_domain_title1').html('');
		}
		
		if (settings_domain_two) {
			var pattern = new RegExp("^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$");

			if(pattern.test(settings_domain_two)){
				$('.errorsettings_domain_title2').html('');				
			}else{
				errors++;
				$('.errorsettings_domain_title2').html('<span class="errorspan">Incorrect domain name</span>');
			}	
		}else{
			$('.errorsettings_domain_title2').html('');
		}*/
		
/*
		this.setState({
			errors: errors
		});
		return formIsValid;*/

			/*if (selectedFont[0].value === undefined || selectedFont[0].value === '') {
		$('.errorfont').html('<span class="errorspan">Please select the font</span>');
		errors++;
		}
		else {
		$('.errorfont').html('');
		}*/

		if (user_image_preview === undefined || user_image_preview === '') { 
		$('.errorcarimage').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorcarimage').html('');
		}
		
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){
		
    	if(Props.settings !== this.props.settings){
    		if(Object.keys(Props.settings).length > 0){
    			const formpayload = Props.settings[0].results;
				console.log(formpayload,"formpayload");
			
				
    			this.setState({formValues:formpayload.supporter_payment});
				

    			this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
				this.setState({settings_product_key:formpayload.settings_product_key});
				this.setState({setting_goal_amount:formpayload.setting_goal_amount});
				this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});				
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({domainSets : formpayload.domainSets});
				this.setState({user_image_preview: formpayload.st_car_icon});
				this.setState({settings_commision_amount: formpayload.settings_commision_amount});
				this.setState({payment_page_content: formpayload.payment_page_content});
				this.setState({notification_popup_theme:formpayload.notification_popup_theme});
				this.setState({dashboard_view:formpayload.dashboard_view});
				this.setState({stripe_payment_type:formpayload.stripe_payment_type,invite_description: formpayload.invite_description});

    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}
    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
    			}else{
    				this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
    			}

    			if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			}else{
    				this.setState({st_position_checked: false});
    			}

			     if(Props.settings[0].font!== '' && Props.settings[0].font!== null){
			        this.setselectedvalue(Props.settings[0].font);
			     }else{
			     	this.setState({selectedFont: [{value:'', label: 'Default'}]})
			     }
				 if(formpayload.invite_image){
					this.setState({invite_image_preview: 'https://admin-va.uxt.design/studentcharity/media/'+formpayload.invite_image})
				 }
    		}
    	}
    	  	if(Props.updatesetting !== this.props.updatesetting){ 
			
    		if(Object.keys(Props.updatesetting).length > 0){
				
    			this.setState({ Loading: false });

    			const formpayload = Props.updatesetting[0].results;
				
    			$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatesetting[0].message+'</h3></div>');
				this.setState({formValues:formpayload.supporter_payment});			
    			this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
				this.setState({settings_product_key:formpayload.settings_product_key});
				this.setState({setting_goal_amount:formpayload.setting_goal_amount});
				this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
    			this.setState({settings_domain_one:formpayload.settings_domain_one});
				this.setState({settings_domain_two:formpayload.settings_domain_two});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({domainSets: formpayload.domainSets});
				this.setState({user_image_preview: formpayload.st_car_icon});
				this.setState({settings_commision_amount: formpayload.settings_commision_amount});
				this.setState({payment_page_content: formpayload.payment_page_content});
				this.setState({notification_popup_theme:formpayload.notification_popup_theme});
				this.setState({dashboard_view:formpayload.dashboard_view});
				this.setState({stripe_payment_type:formpayload.stripe_payment_type,invite_description: formpayload.invite_description});

    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}


    			if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			}else{
    				this.setState({st_position_checked: false});
    			}

    			
    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_checked: true});
    			}else{
    				this.setState({stripe_checked: false});
    			}
    				scrollToTop();

    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}
    	if(Props.fontname !== this.props.fontname){
    		if(Object.keys(Props.fontname).length > 0){
    		    this.setState({ fonttype : Props.fontname[0].fontlist})

    		}
    	}

	}

	setselectedvalue(selectsers){
		
	    const fontArray = selectsers.map((fonttype, index) => ({
	     id: index,
	     name: fonttype,
	    }));
    // Dynamically create select list
    let fonts = [];
    fontArray.map(item =>
    fonts.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedFont : fonts})
  }

	handleClick = () => {
	this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};

	 copyCodeToClipboard(event){
		event.preventDefault;
		const el = this.textArea
		el.select()
		document.execCommand("copy")
   }


   writeText = () => {
	 
	$('.copied_title').html('<span class=""><h3>Copied</h3></span>');

	//onClick={() =>  navigator.clipboard.writeText(tester)}
	let test1 = this.state.settings_access_code;
	
	let  tester =	'<script src="https://admin-va.uxt.design/plugin.js"></script> <div data-client="'+test1+'"  id="yeshiva_container"></div>';
	 navigator.clipboard.writeText(tester);
	setTimeout(
		function() {
			$('.copied_title').html('');
			$('.copied_title').hide();
		}
		.bind(this),
		3000
		);
		
    }

   myCallback = (domainsets) => {
    this.setState({domainSets: domainsets});
  };
  myCallbacksupporterpayment = (paymentsets) => {
    this.setState({paymentSets: paymentsets});
  };
  
  removeImage  = () => {
  	this.setState({user_image_preview:''});
  }
 

   handleChangeFont = selectedFont => {
      this.setState({ selectedFont});
      this.setState({ fontvalue : selectedFont.value });
      if(selectedFont.value === ''){
        //$('.errorfont').html('<span class="errorspan">Please select the font</span>');
      }else{
      	 $('.errorfont').html('');
      }
   }

   
addFormFields() {
	this.setState({errorMessage:[]})
	$('.pres-info-inner').addClass('active');		
		 
	this.setState(({
	  formValues: [...this.state.formValues, { supporter_price_lab: "", supporter_price_val: ""}]
	}))
}

removeFormFields(i) {
	$('.pres-info-inner').removeClass('active');		
  
	let formValues = this.state.formValues;
	formValues.splice(i, 1);
	this.setState({ formValues });
}
prescriptiondatalist() {
	var presdata = this.state.appt_medicinelist;
	if (presdata != "undefined" && presdata != null) {
	  if (Object.keys(presdata).length > 0) {
		const presDetails = presdata.map(
		  (data, Index) => {
			let sno = Index+1;
  
			return (
				<tr key={Index}>
				<td scope="row">{String(data.prescription_name).charAt(0).toUpperCase() + String(data.prescription_name).slice(1)}</td>
				<td>{data.description}</td>
				<td>{data.quantity}</td>
				<td>${data.price}</td>
				</tr>
			);
		  }
		);
		return presDetails;
	  }
	 }   
  }
  handleChangesupporterAmount(i, e) {
	let formValues = this.state.formValues;
	formValues[i][e.target.name] = e.target.value;
	this.setState({ formValues });
  }


  render() {
		const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.hexColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    const defaultScripturl = 'http://car.uxt.design/index.html/';
	let test1 = this.state.settings_access_code;
	//let test = Number(this.state.settings_access_code);
	
	const scripttype = '';
	
		/*const tester =	'<!-- Include <div> tag after the script -->\n<script src="http://car-c.uxt.design/plugin.js"></script>\n<div data-client="'+test+'"  id="car_container"></div>';
		*/
		
		
	let  tester =	'<script src="https://admin-va.uxt.design/plugin.js"></script> <div data-client="'+test1+'"  id="yeshiva_container"></div>';

	let { user_image_preview} = this.state;
	let carImg = '';
	let preview = '';
	

	const fontArray = this.state.fonttype.map ((fonttype, index) => ({
	id: index,
	name: fonttype,
	}));
	// Dynamically create select list
	let fonts = [{value:'', label: 'Default'}];
	fontArray.map(item =>
	fonts.push({ label: item.name.label, value: item.name.value }),
	);

	const {selectedFont,formValues} = this.state;

	if (user_image_preview!== null && user_image_preview!== '') {
	    carImg = carImageUrl + "/" + user_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}

	const style_text = {
	color: 'white',
    backgroundColor: 'black',
    padding: '9px 9px',
    minwidth: '49px',
    height: '37px',
	textalign:'center',
    texttransform: 'capitalize'
}

	
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="setting" />		

	
	<div className="content">	
	<div class="content-wrapper-before"></div>

<div className="car-listing-wrap leaa-admin-head card">
			<div className="content-body">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Settings</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
				{localStorage.getItem('admin_id') == 1 ?<label>Site Name:</label>:<label>Company Name:</label>}
					
					<input type="text" name="settings_site_title" onChange={this.handleInputChange} className="form-control" value={this.state.settings_site_title} />
					<div className="errorsettings_site_title"></div>
				</div>
				<div className="form-group">					
					<label>From Name:</label>
					<input type="text" name="settings_from_name" onChange={this.handleInputChange} className="form-control"value={this.state.settings_from_name} />
					<div className="errorsettings_from_name"></div>
				</div>
				<div className="form-group">
					<label>From Email:</label>
					<input type="text" name="settings_from_email"  onChange={this.handleInputChange} className="form-control" value={this.state.settings_from_email} />
					<div className="errorsettings_from_email"></div>
				</div>

				{localStorage.getItem('admin_id') == 1 ?
             <div className="default-font">
			  <div className="form-group">
					<label>Font:</label>
				     <Select 
                         options={fonts}  
                         value={selectedFont?selectedFont:{ value: '0', label: 'Select Font Type' }}
                         onChange={this.handleChangeFont}
                         placeholder="Select Font Type" />
					<div className="errorfont"></div>
				</div>
				</div> : '' }

				<div className="form-group">					
    				  <label>Payment page Content:</label>
   						<textarea type="text" name="payment_page_content" onChange={this.handleInputChange} className="form-control" value={this.state.payment_page_content} />
    		 	</div>

			</div>
			<div className="form-right">

				<div className="form-group">					
					{localStorage.getItem('admin_id') == 1 ? <label>Admin Email:</label>:<label>Company Email:</label>} 
					<input type="text" className="form-control" onChange={this.handleInputChange} name="settings_admin_email" value={this.state.settings_admin_email} />
					<div className="errorsettings_admin_email"></div>
				</div>
				
				<div className="form-group">
					<label>Contact No:</label>
					<input type="text" className="form-control" name="settings_mobileno"  onChange={this.handleInputChange} value={this.state.settings_mobileno}/>
					<div className="errorsettings_mobileno"></div>
				</div>

				<div className="form-group"> 
					<label>Email Footer Content:</label>
					<input type="text" className="form-control" name="settings_email_footer" onChange={this.handleInputChange} value={this.state.settings_email_footer}/>
					<div className="errorsettings_email_footer"></div>
				</div>

				<div className="default-font">
			  <div className="form-group">
					<label>Stripe Payment Type:</label>
				     <select name="stripe_payment_type" className="form-control" onChange={this.handleInputChange}>
					 	<option selected={this.state.stripe_payment_type == 'Normal'} value="Normal">Normal</option>
						<option selected={this.state.stripe_payment_type == 'Widget'} value="Widget">Widget</option>
					</select>
					
				</div>
				</div>

				<div className="form-group">
					<label>Notification popup theme: </label>
				    <input onChange={this.handleInputChange} type="radio"  name="notification_popup_theme" value="White" checked={this.state.notification_popup_theme == 'White'}/>
	                <span>White</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="notification_popup_theme" value="Black" checked={this.state.notification_popup_theme == "Black"}/>
	                <span>Black</span>
            	</div>

				<div className="form-group">
					<label>Dashboard View: </label>
				    <input onChange={this.handleInputChange} type="radio"  name="dashboard_view" value="list" checked={this.state.dashboard_view == 'list'}/>
	                <span>List View</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="dashboard_view" value="grid" checked={this.state.dashboard_view == "grid"}/>
	                <span>Grid View</span>
            	</div>

				<div className="form-group">
						<label>Plugin Size: </label>
						<br/>
					    <input onChange={this.handleInputChange} type="radio" name="plugin_size" value="600" checked={(this.state.plugin_size == "600" ? "checked" : "" )}/>
		                <span>600px</span>
						
		                 <input onChange={this.handleInputChange} type="radio"  name="plugin_size" value="700" checked={(this.state.plugin_size == "700" ? "checked" : "" )}/>
		                <span>700px</span>
						{/* <input onChange={this.handleInputChange} type="radio"  name="plugin_size" value="900" checked={(this.state.plugin_size == "900" ? "checked" : "" )}/>
		                <span>900px</span> */}
 					</div>
			   {/*<div className="form-group">
					<label>Travel Commission%:</label>
					<input type="text" className="form-control" name="settings_commission_percentage"  onChange={this.handleInputChange} value={this.state.settings_commission_percentage} />
					 <div className="errorsettings_commission_percentage"></div>
	           </div>*/}
				{/* localStorage.getItem('admin_id') == 1 ? <div className="form-group site-bg-clr"> 
				<label>Site Background Color: </label>
						<div style={ styles.swatch } onClick={ this.handleClick }>
						<div style={ styles.color } />
						</div>
						{ this.state.displayColorPicker ? <di* style={ styles.popover }>
						<div style={ styles.cover } onClick={ this.handleClose }/>
						<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
			</di*/} 

			</div>				
			</div>	
		   { localStorage.getItem('admin_id') == 1 ? <div className="title">
				<h4>Mail Configuration Settings</h4>
			</div> : '' }
			
			{ localStorage.getItem('admin_id') == 1 ? <div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox"  name="settings_mail_from_smtp" checked={this.state.smtp_checked}/>
	                <span>Send Mail From Smtp</span>
            </div> : ''}

		{ localStorage.getItem('admin_id') == 1 ? <div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Smtp Host:</label>
					<input type="text" className="form-control" name="settings_smtp_host" onChange={this.handleInputChange}  value={(this.state.settings_smtp_host != 'null')?this.state.settings_smtp_host:''}/>
					<div className="errorsettings_smtp_host"></div>
				</div>
				<div className="form-group">					
					<label>Smtp Username:</label>
					<input type="text" className="form-control" name="settings_smtp_user"  onChange={this.handleInputChange}  value={(this.state.settings_smtp_user != 'null')?this.state.settings_smtp_user:''} />
					<div className="errorsettings_smtp_user"></div>
				</div>					
				<div className="form-group">
					<label>Smtp Password:</label>
					<input type="password" className="form-control" name="settings_smtp_pass" onChange={this.handleInputChange}  value={(this.state.settings_smtp_pass != 'null')?this.state.settings_smtp_pass:''} />
				<div className="errorsettings_smtp_pass"></div>
				</div>
			</div>			
			<div className="form-right">
				<div className="form-group">					
					<label>Smtp Port:</label>
					<input type="text" className="form-control"  name="settings_smtp_port" onChange={this.handleInputChange}  value={(this.state.settings_smtp_port != 'null')?this.state.settings_smtp_port:''} />
				<div className="errorsettings_smtp_port"></div>
				</div>
				<div className="form-group"> 
					<label>Mail Path:</label>
					<input type="text" className="form-control" name="settings_mailpath" onChange={this.handleInputChange} placeholder=""  value={(this.state.settings_mailpath != 'null')?this.state.settings_mailpath:''}/>
				</div>
			</div>	
			</div> : ''}
			{ localStorage.getItem('admin_id') == 1 ? 
			<div className="title">
				<h4>Commision Settings</h4>
			</div>  : ''}

			{ localStorage.getItem('admin_id') == 1 ? 
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Commision Percentage %:</label>
					<input type="text" className="form-control" name="settings_commision_amount" onChange={this.handleInputChange}  value={(this.state.settings_commision_amount != 'null')?this.state.settings_commision_amount:''}/>
					<div className="errorsettings_commision_amount"></div>
				</div>
				</div>
				</div> : ''}

				{ localStorage.getItem('admin_id') == 1 ? 
			<div className="title">
				<h4> Supporter Additional Fee</h4>
			</div>  : ''}

			{ localStorage.getItem('admin_id') == 1 ? 
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Additional Fee $:</label>
					<input type="text" className="form-control" name="supporter_additional_fee" onChange={this.handleInputChange}  value={(this.state.supporter_additional_fee != 'null')?this.state.supporter_additional_fee:''}/>
					<div className="errorsupporter_additional_fee"></div>
				</div>
				</div>
				</div> : ''}

				{ localStorage.getItem('admin_id') == 1 ? 
			<div className="title">
				<h4> Invite Supporter Content</h4>
			</div>  : ''}

			{ localStorage.getItem('admin_id') == 1 ? 
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Invite description:</label>
					<textarea type="text" name="invite_description" onChange={this.handleInputChange} className="form-control" value={this.state.invite_description} />
				</div>
				</div>
				<div className="form-left">
				<div className="form-group">
					<label>Invite Supporter Image:</label>
					<div className="choose-file">
					<span className="profile_btn"><input type="file" name="invite_image" onChange={this.onChangeHandler} /></span>
					</div>
					{this.state.invite_image_preview != '' ? <img src={this.state.invite_image_preview} width={100} height={100} />:''}
					
				</div>
				</div>
				</div> : ''}


				{ localStorage.getItem('admin_id') == 1 ? 
			<div className="title">
				<h4> Supporter Payment Settings</h4>
			</div>  : ''}

			{ localStorage.getItem('admin_id') == 1 ? <>
			<div className="form-row mb-0">
				{this.state.formValues.map((element, index) => {
					return(<>
							<div className="form-left">
								<div className="form-group">
									<label>Payment Label {index+1}:</label>
									<input type="text" className="form-control input-focus" placeholder="Label" name="supporter_price_lab" value={element.supporter_price_lab || ""}  onChange={e => this.handleChangesupporterAmount(index, e)} 
											/>
									<span className="errorspan">
										{element.supporter_price_labCheck}
									</span>	
								</div>	
										
							</div>			
							<div className="form-right">
								<div className="form-group">					
								<label>Payment Value {index+1} ($):</label>
								<div className="goal-settings">
									<input type="number" name="supporter_price_val" className="form-control input-focus" placeholder="Price" onChange={e => this.handleChangesupporterAmount(index, e)} value={element.supporter_price_val || ""}   />
									{index ? <button type="button"  className="button btn-sm remove remv-btn" onClick={() => this.removeFormFields(index)}> <i class="fa fa-times" aria-hidden="true"></i> </button> :null}
									</div>
									<span className="errorspan">
										{element.supporter_price_valCheck}
									</span>
								</div>								
							</div>
					</>)
				})}
			</div>
			<button href="#" type="button" className='suporter_paymet_add_btn' onClick={() => this.addFormFields()}> <i class="fa fa-plus" aria-hidden="true"></i> Add</button>						
			{this.prescriptiondatalist()}</>

				: ''}

			{ localStorage.getItem('admin_id') == 1 ? <div className="title">
				<h4>Domain & Embed Code</h4>
			</div> : '' }
			{ localStorage.getItem('admin_id') == 1 ? <div className="form-row">
				<div className="form-left">
				<h4>Domain </h4>
				<div className="domain-url">
					<Addinputsimple myCallback={this.myCallback} domainname={this.state.domainSets}/>
					</div>
				</div>	
				<div className="form-right">
					<div className="form-group">
						<label>Embed Code:</label>
						
						<p className="right-side-header"  id="embed_code" name="settings_embedcode" value={tester} title="Copy Text" >{tester}</p>
						
						<br></br>
						<a id='copytext' style={style_text} onClick={this.writeText}   >Copy  </a> <br></br>
						<div><br></br><div className="copied_title"></div></div>
						<div className="errorsettings_embedcode_title"></div>
						
						
					</div>
					
				</div>				
			</div> : '' }
			{ localStorage.getItem('admin_id') == 1 ? <h5>Plugin Position (Numbers Only)</h5> : '' }
			{ localStorage.getItem('admin_id') == 1 ? <div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						<label>Left : %</label>
						<input type="number" className="form-control" name="st_position_left"  onChange={this.handleInputChange} value={this.state.st_position_left}/>
						<div className="errorsettings_st_position_left"></div>
					</div>
					<div className="form-group">
						<label>Right : %</label>
						<input type="number" className="form-control" name="st_position_right"  onChange={this.handleInputChange} value={this.state.st_position_right}/>
						<div className="errorsettings_st_position_right"></div>
					</div>
					<div className="form-group">
						<label>Position : </label>
						<input onChange={this.handleInputChange} type="checkbox"  name="st_position" checked={this.state.st_position_checked}/>
	                    <span>Fixed</span>
					</div>
				</div>
				<div className="form-right">
					<div className="form-group">
						<label>Top : %</label>
						<input type="number" className="form-control" name="st_position_top"  onChange={this.handleInputChange} value={this.state.st_position_top}/>
						<div className="errorsettings_st_position_top"></div>
					</div>
					<div className="form-group">
						<label>Bottom : %</label>
						<input type="number" className="form-control" name="st_position_bottom"  onChange={this.handleInputChange} value={this.state.st_position_bottom}/>
						<div className="errorsettings_st_position_bottom"></div>
					</div>
				</div>
			</div> : '' }
			

			{/* <div className="form-group">
					<label>Bus Icon:</label>
				   <div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
                </div>
                {preview}
                {user_image_preview?<a href="javascript:void(0);" onClick={this.removeImage}>X</a>:''}
                <div className="errorcarimage"></div> 
	</div> */}

		 <div className="title">
				<h4>Stripe Configuration</h4>
			</div> 
		   <div className="form-group">
				    <input onChange={this.handleInputChange} type="radio" name="settings_enable_live" checked={this.state.stripe_live_checked}/>
	                <span>Live</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="settings_enable_sandbox" checked={this.state.stripe_sandbox_checked}/>
	                <span>SandBox</span>
            </div> 
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Public Key:</label>
					<input type="text" className="form-control" name="settings_public_key" onChange={this.handleInputChange}  value={this.state.settings_public_key}/>
					<div className="errorsettings_public_key"></div>
				</div>

				<div className="form-group">
					<label>Product Key :</label>
					<input type="text" className="form-control" name="settings_product_key" onChange={this.handleInputChange}  value={this.state.settings_product_key}/>
					<div className="errorsettings_product_key"></div>
					<span>Note : For Recurring payment(Monthly) the product key use.</span>
				</div>
					
			</div>	
			<div className="form-right">
				
				<div className="form-group">					
					<label>Secret Key:</label>
					<input type="password" className="form-control" name="settings_secret_key"  onChange={this.handleInputChange}  value={this.state.settings_secret_key} />
					<div className="errorsettings_secret_key"></div>
				</div>	
			</div>	
      	</div>		
			<div className="btn-group export">	
				
                <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data">Loading</span> 
                                } Submit
			    </button>	
			</div>

			</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

// const mapStateTopProps = (state) => {
//   return {
// 	settings: state.settings,
// 	updatesetting: state.updatesetting,
// 	fontname : state.fontname
//   }
// }
// const mapDispatchToProps = (dispatch) => {
//     return {
// 		getSettingData: (formPayload) =>{
// 		dispatch({ type: GET_SETTINGDATA, formPayload});
// 		},
// 		getFontName: () => {
// 			dispatch({ type: GET_FONTNAME });
// 		},
// 		getUpdateSetting: (formPayload) => {
// 		dispatch({ type: GET_UPDATESETTING, formPayload });
//    	  }
//     }
// }
export default Setting;
