import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };


class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			fontname:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
		    selectedFile: null,
			Loading:false,
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('Font Add');
    }

  /*onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
	  
	  this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ font_file: this.imageArray })	
		
     
    }; */
	
	onFileChange = (e) =>{
	this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ font_file: this.imageArray })		
    }

/*  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        user_image_name: file.name,
        user_image_preview: reader.result,
        image: file
      });
    };
    reader.readAsDataURL(file);
  }*/

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					selectedFile : formPayload.selectedFile,
					status       : status,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				var filesdata = this.state.font_file;
				
				for (var i in filesdata) {
				 formData.append('font_file[]',filesdata[i])
				}	
				
				axios.post(apiUrl+"font/add",qs.stringify(postObject)).then(res => {
					if(res.data.status === 'success'){
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							this.setState({
								
								Loading:false
							});
							 setTimeout(
							 function(){							
								 $('.success_message').html('');
								 // const navigate = this.props.navigate;
								 this.props.navigate('/fonts');
								}
							.bind(this),
							3000
							);
						}else{
						 this.setState({Loading:false});
							$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
				});
				// this.props.getAddFont(formData,config);
				
			}
	}

	validateForm() {

		const {fontname,fontfamilyname,selectedFile,fonturl} = this.state;

		let errors = 0;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}
		
		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

  render() {
  	const {selectedOption} = this.state;


    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="fonts" />  

	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div className="title">
				<h4>Add Font</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Font Name:</label>
				    <input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
					<div className="errorfont_name"></div>
				</div>
				
				<div className="form-group">
					<label>Font Import url:</label>
					<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
					<div className="errorfonturl"></div>
				</div>
				
			</div>		
			<div className="form-right">

				<div className="form-group" style={{display:"none"}}>
					<label>Font:</label>
				 <input type="file" onChange={this.onFileChange} multiple /> 
                <div className="errorfile"></div> 
                </div>

				<div className="form-group">
					<label>Exact Family Name:</label>
				    <input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
					<div className="errorfontfamilyname"></div>
				</div>

				<div className="form-group">					
					<label>Status:</label>
						<Select 
						value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
						options={lang.common.status_option} 
						onChange={this.handleChange}
						/>
				</div>
			</div>		

			</div>		

			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	</div>
    </div>
    );
  }
}

export default withRouter(Add);