import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './common/css/font-awesome.min.css';
import './common/css/custom.css';
import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import DashboardList from "./components/Dashboard/List";

import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserView from "./components/User/View";
// import UserImport from "./components/User/ImportUser";

import CompanyList from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";

import StudentList from "./components/Student/List";
import StudentAdd from "./components/Student/Add";
import StudentEdit from "./components/Student/Edit";
import StudentView from "./components/Student/View";

// import StudentImport from "./components/Student/ImportStudent";

import TutorList from "./components/Tutor/List";

import TutorAdd from "./components/Tutor/Add";
// import TutorEdit from "./components/Tutor/Edit";
import TutorView from "./components/Tutor/View";
// import TutorImport from "./components/Tutor/ImportTutor";

import KollelList from "./components/Kollel/List";
import KollelAdd from "./components/Kollel/Add";
import KollelEdit from "./components/Kollel/Edit";
import KollelView from "./components/Kollel/View";

import CompaignList from "./components/Compaign/List";
import CompaignAdd from "./components/Compaign/Add";
import CompaignEdit from "./components/Compaign/Edit";
import CompaignView from "./components/Compaign/View";

import ShulesList from "./components/Shules/List";
import ShulesAdd from "./components/Shules/Add";
import ShulesEdit from "./components/Shules/Edit";
import ShulesView from "./components/Shules/View";
import AddSupporter from "./components/Shules/AddSupporter";


// import UniversityList from "./components/University/List";
// import UniversityAdd from "./components/University/Add";
// import UniversityEdit from "./components/University/Edit";
// import UniversityView from "./components/University/View";

// import FundList from "./components/Fund/List";
// import FundAdd from "./components/Fund/Add";
// import FundEdit from "./components/Fund/Edit";
// import FundView from "./components/Fund/View";

import PaymentHistoryList from "./components/PaymentHistory/List";
import PaymentHistoryView from "./components/PaymentHistory/View";

import PaymentHistorySheduledList from "./components/PaymentSheduled/List";
// import PaymentHistorySheduledView from "./components/PaymentSheduled/View";

import CmsList from "./components/Cms/List";
// import CmsAdd from "./components/Cms/Add";
import CmsEdit from "./components/Cms/Edit";

// import Yeshivasetting from './components/Account/Yeshivasetting';
// import YeshivaPayment from './components/Universitypanel/PaymentHistory';

// import Kollelsetting from './components/Account/Kollelsetting';
// import KollelPayment from './components/Kollelpanel/PaymentHistory';


// import KollelStudentList from "./components/KollelStudent/List";
// import KollelStudentAdd from "./components/KollelStudent/Add";
// import KollelStudentEdit from "./components/KollelStudent/Edit";
// import KollelStudentView from "./components/KollelStudent/View";

import MemList from "./components/Donation/List";
import MemEdit from "./components/Donation/Edit";
import MemAdd from "./components/Donation/Add";
import MemView from "./components/Donation/View";

import DonationcategoryList from "./components/DonationCategory/List";
import DonationcategoryAdd from "./components/DonationCategory/Add";
import DonationcategoryEdit from "./components/DonationCategory/Edit";

import SubscribersList from "./components/Subscribers/List";
import SubscribersView from "./components/Subscribers/View";

// import FaqCategoryList from "./components/FaqCategory/List";
// import FaqCategoryAdd from "./components/FaqCategory/Add";
// import FaqCategoryEdit from "./components/FaqCategory/Edit";

// import FaqList from "./components/Faq/List";
// import FaqAdd from "./components/Faq/Add";
// import FaqEdit from "./components/Faq/Edit";
// import FaqImport from "./components/Faq/ImportFaq";

import PackageList from "./components/Package/List";
import PackageEdit from "./components/Package/Edit";
import PackageAdd from "./components/Package/Add";
import PackageView from "./components/Package/View";

// import RankList from "./components/RankManagement/List";
// import RankEdit from "./components/RankManagement/Edit";
// import RankAdd from "./components/RankManagement/Add";
// import RankView from "./components/RankManagement/View";

import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
// import FontEdit from "./components/Fonts/Edit";

import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/profile" element={< Adminprofile />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/changepassword" element={<Changepassword />} />
      <Route path="/forget_password" element={<Forgetpassword/>} />
      <Route path="/reset_password/:key" element={<Resetpassword/>} />
      <Route path="/dashboard"element={ <DashboardList/> } />  
      
      <Route path="/user" element={ <UserList/> } />
       <Route path="/edit/:ID" element={ <UserEdit/> }  />
      <Route path="/view/:ID" element={ <UserView/> } /> 
      <Route path="/user-add" element={ <UserAdd/> } />
      {/* UserAdd<Route path="/user-import" element={ <UserImport/> } /> */} 

      <Route path="/company" element={<CompanyList/>} />
      <Route path="/company-add" element={<CompanyAdd/>} />
      <Route path="/company-edit/:ID" element={<CompanyEdit/>} />
      
      <Route path="/student" element={<StudentList/>} />
      <Route path="/student-add" element={<StudentAdd/>} />
       <Route path="/student-edit/:ID" element={<StudentEdit/>} />
      <Route path="/student-view/:ID" element={<StudentView/>} /> 

      <Route path="/tutor" element={<TutorList/>} />
      <Route path="/tutor-add" element={<TutorAdd/>} />
      {/*<Route path="/tutor-edit/:ID" element={<TutorEdit/>} />*/}
      <Route path="/tutor-view/:ID" element={<TutorView/>} /> 
      {/* <Route path="/tutor-import" element={<TutorImport/>} />  */}

      <Route path="/kollel" element={<KollelList/>} />
       <Route path="/kollel-add" element={<KollelAdd/>} />
      <Route path="/kollel-edit/:ID" element={<KollelEdit/>} />
      <Route path="/kollel-view/:ID" element={<KollelView/>} /> 

      <Route path="/compaign" element={<CompaignList/>} />
      <Route path="/compaign-add" element={<CompaignAdd/>} />
      <Route path="/compaign-edit/:ID" element={<CompaignEdit/>} />
      <Route path="/compaign-view/:ID" element={<CompaignView/>} />

      <Route path="/shules" element={<ShulesList/>} />
      <Route path="/shules-add" element={<ShulesAdd/>} />
      <Route path="/shules-edit/:ID" element={<ShulesEdit/>} />
      <Route path="/shules-view/:ID" element={<ShulesView/>} />
      <Route path="/add-supporter/:ID" element={<AddSupporter/>} /> 




{/*  

        <Route path="/talmid" element={<TalmidList/>} />
        <Route path="/talmid-add" element={<TalmidAdd/>} />
        <Route path="/talmid-edit/:ID" element={<TalmidEdit/>} />
        <Route path="/talmid-view/:ID" element={<TalmidView/>} />

        <Route path="/university" element={<UniversityList/>} />
        <Route path="/university-add" element={<UniversityAdd/>} />
        <Route path="/university-edit/:ID" element={<UniversityEdit/>} />
        <Route path="/university-view/:ID" element={<UniversityView/>} />
        
        <Route path="/fund" element={<FundList/>} />
        <Route path="/fund-add" element={<FundAdd/>} />
        <Route path="/fund-edit/:ID" element={<FundEdit/>} />
        <Route path="/fund-view/:ID" element={<FundView/>} />

        */}
         <Route path="/payment-history" element={<PaymentHistoryList/>} />
         <Route path="/payment-view/:ID" element={<PaymentHistoryView/>} />

        <Route path="/payment-sheduled" element={<PaymentHistorySheduledList/>} />
 {/*       <Route path="/payment-Sheduled-view/:ID" element={<PaymentHistorySheduledView/>} />
 */}
    <Route path="/cms/edit/:ID" element={<CmsEdit/>} />
        <Route path="/cms" element={<CmsList/>} />
        {/*<Route path="/cms-add" element={<CmsAdd/>} />
        <Route path="/yeshivapayment-history" element={<YeshivaPayment/>} />
        <Route path="/kollelpayment-history" element={<KollelPayment/>}/>

        
        <Route path="/kollelstudent" element={<KollelStudentList/>} />
        <Route path="/kollelstudent-add" element={<KollelStudentAdd/>} />
        <Route path="/kollelstudent-edit/:ID" element={<KollelStudentEdit/>} />
        <Route path="/kollelstudent-view/:ID" element={<KollelStudentView/>} />

        
*/}
        <Route path="/package" element={<PackageList/>} />
        <Route path="/package-view/:ID" element={<PackageView/>} />
        <Route path="/package-edit/:ID" element={<PackageEdit/>} />
        
        <Route path="/package-add" element={<PackageAdd/>} />

        <Route path="/donation" element={<MemList/>} />
         <Route path="/donation-edit/:ID" element={<MemEdit/>} />
        <Route path="/donation-view/:ID" element={<MemView/>} />
        <Route path="/donation-add" element={<MemAdd/>} /> 
        
        <Route path="/donationcategory" element={<DonationcategoryList/>} />
        <Route path="/donationcategory-edit/:ID" element={<DonationcategoryEdit/>} />
        <Route path="/donationcategory-add" element={<DonationcategoryAdd/>} />

        <Route path="/subscribers" element={<SubscribersList/>} />
        <Route path="/subscribers-view/:ID" element={<SubscribersView/>} />  
        {/*
        <Route path="/rank" element={<RankList/>} />
        <Route path="/rank-add" element={<RankAdd/>} />
        <Route path="/rank-edit/:ID" element={<RankEdit/>} />
        <Route path="/rank-view/:ID" element={<RankView/>} />
        */}
        <Route path="/dashboardmanagement/edit/:ID" element={<DashboardEdit/>} />
        <Route path="/dashboardmanagement" element={<DashboardManagement/>} />
        <Route path="/dashboardmanagement-add" element={<DashboardAdd/>} /> 

        <Route path="/fonts" element={<Fontlist/>} />
        <Route path="/font-add" element={<FontAdd/>} />
         {/*<Route path="/font/edit/:ID" component={FontEdit} /> */}
         <Route path="/templates" element={<TemplateList/>} />
         <Route path="/template/add" element={<TemplateAdd/>} />
       <Route path="/templates-edit/:ID" element={<TemplateEdit/>} />
       
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
